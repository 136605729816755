

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { ICRUDQ } from '@/model/interface';
import { ViewModeType } from '@/model/enum';
import LabelService from '@/service/label';
import { ImporExcelEntityModel } from '@/entity-model/label-entity';

@Component
export default class ImportExcelComponent extends FormDialogComponent<ImporExcelEntityModel> {
    dialogOpen(importModel: ImporExcelEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '导入';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                LabelService.importExcel(this.jtlForm.formModel).then(res => {
                    const failMessage = (res.failReasons && res.failReasons.length > 0) ? `导入失败：${res.failReasons.join(',')}` : '';
                    this.showMessageSuccess(`导入成功${res.successCount}个，失败${res.failCount}个！${failMessage}`);
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
